<template>
  <div class="last-transactions">
    <CCard>
      <CCardBody>
        <div
          class="d-flex cursor-pointer align-items-center"
          @click="fetchTransactions"
        >
          <em
            class="fa-solid text-primary mb-2"
            :class="showTransactions ? 'fa-angle-down' : 'fa-angle-right'"
          />
          <h5
            class="text-primary mx-2 mb-2"
            v-c-tooltip="'Click to Show/Hide Transactions'"
          >
            Recent Transactions
          </h5>
        </div>
        <CCollapse v-show="showTransactions">
          <ul class="p-2">
            <ol
              v-for="(transaction, index) in lastTransactions"
              :key="index"
              class="pb-2 border-dot-bottom transaction px-1"
            >
              <div class="d-flex align-items-center">
                <div class="icon border text-center px-2">
                  <i :class="transaction.icon"></i>
                </div>
                <div class="content font-weight-400 mx-2">
                  <div v-html="transaction.content"></div>

                  <small>{{ transaction.created_on | formatDateTime }}</small>
                </div>
              </div>
            </ol>
          </ul>
        </CCollapse>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import m from "moment";
export default {
  data() {
    return {
      showTransactions: false,
    };
  },
  computed: {
    ...mapGetters(["getLastTransactions"]),
    lastTransactions() {
      const actions = ["job_candidate_process", "candidate_audits"];
      return this.getLastTransactions
        .filter((v) => actions.includes(v?.recent_action))
        .map((v) => {
          const {
            recent_action,
            actions,
            candidate_uid,
            candidate_display_uid,
            job_id,
            job_display_uid,
            status,
            sub_status,
            previous_status,
            previous_sub_status,
            created_on,
            comments,
            candidate_name,
            job_title,
          } = v;
          const icons = {
            candidate_audits: "fa-solid fa-user-doctor color-green",
            job_candidate_process: "fa-solid fa-briefcase-medical color-red",
          };
          let content = comments;
          const candidate_details_link =
            candidate_uid && candidate_display_uid && candidate_name
              ? `<a href='/candidate/${candidate_uid}'>${candidate_name} | ${candidate_display_uid}</a>`
              : "";
          const job_details_link =
            job_id && job_title && job_display_uid
              ? `<a href='/job-list-preview/${job_id}'>${job_title} | ${job_display_uid}</a>`
              : "";
          if (recent_action === "job_candidate_process") {
            const from_msg = previous_status
              ? `from ${previous_status}${
                  previous_sub_status ? "(" + previous_sub_status + ")" : ""
                }`
              : "";
            const to_msg = status
              ? `to ${status}${sub_status ? "(" + sub_status + ")" : ""}`
              : "";
            content = `Candidate ${candidate_details_link} 
            moved ${from_msg} ${to_msg} for Job ${job_details_link}`;
          } else if (recent_action === "candidate_audits") {
            content = `${comments} for ${candidate_details_link}`;
          }
          content = `<p class='mt-1 mb-1'>${content}</p>`;
          return {
            icon: icons[recent_action],
            content,
            created_on,
          };
        });
    },
  },
  methods: {
    ...mapActions(["fetchLastTransactions"]),
    fetchTransactions(){
      if(!this.showTransactions){
        return this.fetchLastTransactions().then(res=>{
          this.showTransactions = !this.showTransactions;
        });
      }
      return this.showTransactions = !this.showTransactions;
    }
  },
  filters: {
    formatDateTime(data) {
      if (data) return m.utc(data).local().format("DD-MMM-YYYY hh:mm:ss A");
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  width: 40px;
  height: 40px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.4em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  border-radius: 50%;
}
.border-dot-bottom {
  border-bottom: dotted #eee 2px;
}
.color-green {
  color: #50d38a !important;
}
.color-red {
  color: #dc0050 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
</style>
